<template>


<h1 class="showcase-title">showcase</h1>

    <div class="entry-holder">
      <div class="entry" v-for="(project, i) in showcase.projects" :key="i">
        <!-- {{project.title}} -->
        <router-link :to="`/showcase/`+project.title">
          <img  class='project-gif'  :src="`./img/showcase/` + project.title + `.gif`">
        </router-link>
      </div>

      <!--       
      <div class="entry">        
        <img class='project-gif' src="/img/showcase/audi.gif">
      </div> -->
    </div>

  

</template>


<script>

import { onBeforeMount, onMounted, shallowRef, toRaw, markRaw, computed, watch } from 'vue';
import { useStore } from 'vuex';
import showcase from '@/helpers/Showcase';

export default {  

  setup() {
    const store = useStore()

    onBeforeMount( async() => {
      store.dispatch("site/setTitle", "LYHCO");
      const metadata = [
            { name:"description", content:"This is the login page description" },
            { name: "keywords", content: "Tech, web technology, AWS cloud," }
        ];
      store.dispatch("site/setMetadata", metadata);
    })
    
    onMounted(()=>{

    })
    


    return {
      showcase,
    }
  }

};
</script>


<style lang="scss" scoped>

      h1.showcase-title { margin: 10px 5px 5px 5px; position: relative; font-size: 14px; text-transform:uppercase; }
      div.entry { display:inline-block; width:100px; height:57px; overflow:hidden; }
      div.entry img.project-gif { width:100px; padding:3px; }

</style>

